import { Navigate, createBrowserRouter } from "react-router-dom";
import { PATH } from "./utils/pagePath";
import ProtectedRoute from "./components/ProtectedRoute"
import PostLogin from "./layouts/PostLogin"
import { lazy } from "react";
import PageNotFound from "./components/PageNotFound"

const LazyBalanceSheet =lazy(()=> import("./pages/BalanceSheet"))
const LazyCashflow =lazy(()=> import("./pages/Cashflow"))
const LazyConfigure =lazy(()=> import("./pages/Configure"))
const LazyLogin =lazy(()=> import('./pages/LoginForm'))
const LazyBalanceGenerate =lazy(()=> import('./pages/BalanceGenerate'))
const LazyCashflowGenerate =lazy(()=>import('./pages/CashflowGenerate'))

export const router = createBrowserRouter([
  {
    path: PATH.DEFAULT,
    // element: <Navigate to={PATH.LOGIN} replace />,
    element: <LazyLogin />,
    
  },
  {
    element: (
      <ProtectedRoute>
        <PostLogin />
      </ProtectedRoute>
    ),
    children: [
      {
        path: PATH.BALANCE_SHEET,
        element: <LazyBalanceSheet/>,
      },
      {
        path: PATH.CASHFLOW_SHEET,
        element: <LazyCashflow reportName="cashFlow" />,
      },
      {
        path: PATH.CONFIGURE,
        element: <LazyConfigure/>,
      },
      {
        path: PATH.BALANCE_GENERATE,
        element: <LazyBalanceGenerate/>,
      },
      {
        path: PATH.CASHFLOW_GENERATE,
        element: <LazyCashflowGenerate/>,
      },
    ],
  },
  {
    element: <PageNotFound />,
    path: PATH.PAGE_NOT_FOUND,
  },
]);
