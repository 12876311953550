import React, { memo } from "react";
import styles from "./PostLogin.module.scss";
import LOGO from "../../assets/logo.png";
import { sideBarNavigation } from "../../utils/sideBarNavigation";
import { NavLink, useLocation } from "react-router-dom";

const LeftSideBar = (props) => {
  const location = useLocation();

  return (
    <div className={styles.leftSideContainer}>
      <div className={styles.logoContainer}>
        <img src={LOGO} alt="" className={styles.logo} />
      </div>
      <div className={styles.navContainer}>
        {sideBarNavigation.map((data) => (
          <NavLink
            key={data.id}
            className={({ isActive }) =>
              `${styles.navLink} ${isActive && styles.activeLink}`
            }
            to={data.path}
            state={{ previousPath: location.pathname }}
          >
            <img src={data?.icon} alt="" className={styles.icon} />
            <p>{data.pageName}</p>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default memo(LeftSideBar);
