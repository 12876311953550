import { PATH } from "./pagePath";
import BALANCESHEET_ICON from '../assets/balanceSheetIcon.png';
import CASHFLOW_ICON from '../assets/cashflowIcon.png';
import CONFIGURE_ICON from '../assets/configureIcon.png';
import { PAGENAME } from "./constants";

export const sideBarNavigation = [
    {
        id:1,
        path: PATH.BALANCE_SHEET,
        icon: BALANCESHEET_ICON,
        pageName: PAGENAME.BALANCESHEET
    },
    {
        id:2,
        path: PATH.CASHFLOW_SHEET,
        icon: CASHFLOW_ICON,
        pageName: PAGENAME.CASHFLOWSHEET
    },
    {
        id:3,
        path: PATH.CONFIGURE,
        icon: CONFIGURE_ICON,
        pageName: PAGENAME.CONFIGURE
    }
]