export const PAGENAME = {
  BALANCESHEET: "Balance Sheet",
  CASHFLOWSHEET: "Cash Flow",
  CONFIGURE: "Configuration",
  MANUAL:"Manual"
};

export const PAGINATION = {
  ROWS_PER_PAGE: [10, 25, 50],
  DEFAULT_ROWS_PER_PAGE: 10,
  DEFAULT_CURRENT_PAGE: 1,
  NO_BREAK_PAGE: 4,
};

export default function dateConverter(str) {
  let DateStr = str.toString();
  var mnths = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    },
    date = DateStr.split(" ");

  return [mnths[date[1]], date[3]].join("/");
}
 export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;