import React from "react";
import { Navigate } from "react-router-dom";
import { PATH } from "../../utils/pagePath";

const index = (props) => {
  // const isSignedIn = localStorage.getItem("token"); //add here token storage location;
  const isSignedIn = localStorage.getItem('token') ? true : false; //add here token storage location;

  if (!isSignedIn) {
    return <Navigate to={PATH.DEFAULT} replace />;
  }

  return props.children;
};

export default index;
